import * as React from "react";
import IframeResizer from "iframe-resizer-react";

const GiftCard = () => {
  return (
    <React.Fragment>
      <section className="gift-card">
        <h2>Lahjakortit</h2>
        <p>Lahjakortti hoitolaan on aina varma lahjavalinta!</p>
        <p>
          Lahjakortit ovat voimassa 6kk ostopäivästä, ja voit ostaa sen joko
          summalle tai tietylle hoidolle. Lahjan voi käyttää kerralla tai
          osissa, hoitoihin, tuotteisiin tai molempiin. Lahjakortin saat
          hoitolasta tai verkkokaupasta.
        </p>
        <p>
          Verkkokaupasta lahjakortin voit ostaa vain valitsemallesi summalle.
          Maksuvaihtoehtoina pankki- tai luottokortti. Lahjakortin voi tulostaa
          tai toimittaa sähköisesti lahjan saajalle. Asioinnin yhteydessä
          riittää, että käyttäjällä on tallessa lahjakortin koodi.
        </p>
      </section>
      <section>
        <IframeResizer
          width="100%"
          frameBorder="0"
          src="https://varaa.timma.fi/giftcard/Kauneudenilo"
          id="giftCardIframe10166"
          style={{ width: "1px", minWidth: "100%" }}
        />
      </section>
    </React.Fragment>
  );
};

export default GiftCard;
