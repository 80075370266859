import * as React from "react";
import Layout from "../components/Layout";
import Services from "../components/Services";
import Seo from "../components/Seo";
import GiftCard from "../components/GiftCard";

// markup
const GiftCardPage = () => {
  return (
    <Layout theme="light" heroSmall>
      <Seo
        title="Lahjakortti - Kauneuden ilo"
        description="Lahjakortti kauneushoitolaan Jyväskylässä. Voit varata aikasi nettiajanvarauksen kautta 24/7, tai ottamalla yhteyttä puhelimitse numeroon 040 846 4160. Voit myös tulla tekemään varauksen paikanpäälle. Jos emme pääse puhelimeen, soitamme sinulle takaisin heti kun vapaudumme."
      />
      <GiftCard />
      <Services />
    </Layout>
  );
};

export default GiftCardPage;
